* {
  box-sizing: border-box;
}

html {
  background: #fff;
  color: #000;
  font: 20px/1.5 $font;
}

html, body {
  background: url('/images/bg.jpg') top left repeat;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.1;
}

img {
  height: auto;
  width: 100%;
}

a {
  border-bottom: solid 1px lighten($link-color, 50%);
  color: $link-color;
  text-decoration: none;

  &:hover, &:active {
    color: $accent-color;
    border-bottom-color: lighten($accent-color, 62%);

    figcaption {
      border-bottom-color: lighten($accent-color, 62%);
    }
  }
}

.container {
  @include clearfix;

  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
}

.phone {
  white-space: nowrap;
}

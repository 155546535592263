.global-header {
  @include clearfix;
  background: url('/images/bg-header.jpg') top left repeat;
  padding: 2% 10% 1rem 10%;

  @media screen and ($md) {
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
  }

  @media screen and ($sm) {
    padding: 3rem 20px 1.5rem 20px;
  }

  .logo {
    max-height: 60px;
    margin: 0 auto;
    max-width: 328px;
  }
}

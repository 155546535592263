@import "~node_modules/bourbon/core/bourbon";

$sm: "max-width: 480px";
$md: "max-width: 768px";
$lg: "min-width: 769px";

$accent-color: #8d0500;
$link-color: #07c;
$font: "Averia Serif Libre", "Times New Roman", serif;

@mixin filter($params) {
  @include prefixer(filter, $params, webkit moz ms o spec);
}

@import "normalize";
@import "common";
@import "components/global-header";

.hero,
.copyright {
  @include clearfix;
  border-bottom: solid 1px #efefef;
  padding: 3rem 10%;

  @media screen and ($md) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media screen and ($sm) {
    padding: 1.5rem 20px;
  }

  h1 {
    clear: both;
    color: $accent-color;
    font-size: 2rem;
    font-weight: bolder;
    margin: 0 0 1.5rem 0;
    text-align: center;
  }
}

.copyright {
  border: 0;
  color: grey;
  font-size: small;
  margin: 0;
  padding-bottom: 1.5rem;

  @media screen and ($md) {
    text-align: center;
  }
}
